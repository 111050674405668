// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.


import initialState from './initialState';
import { reducer as fetchSubstitutionsDataReducer } from './fetchSubstitutionsData';
import { reducer as resetSubstitutionsDataReducer } from './resetSubstitutionsData';
import { reducer as saveSearchConditionDataReducer } from './saveSearchConditionData';
import { reducer as fetchValidationItemDataReducer } from './fetchValidationItemData';
import { reducer as fetchConceptDataReducer } from './fetchConceptData';
import { reducer as fetchOutsDataReducer } from './fetchOutsData';
import { reducer as fetchDropoutsDataReducer } from './fetchDropoutsData';
import { reducer as resetSearchConditionDataReducer } from './resetSearchConditionData';
import { reducer as fetchOrderDetailDataReducer } from './fetchOrderDetailData';
import { reducer as fetchInventoryDataReducer } from './fetchInventoryData';
import { reducer as resetOutsDataReducer } from './resetOutsData';
import { reducer as resetDropoutsDataReducer } from './resetDropoutsData';
import { reducer as fetchValidationCustomerDataReducer } from './fetchValidationCustomerData';
import { reducer as updateSubstitutionsDataReducer } from './updateSubstitutionsData';
import { reducer as exportOutsDataReducer } from './exportOutsData';
import { reducer as fetchAddedSubsDataReducer } from './fetchAddedSubsData';
import { reducer as resetConceptDataReducer } from './resetConceptData';
import { reducer as resetValidationItemDataReducer } from './resetValidationItemData';
import { reducer as downloadSubstitutionsFileReducer } from './downloadSubstitutionsFile';
import { reducer as fetchAccountDataReducer } from './fetchAccountData';
import { reducer as resetAccountDataReducer } from './resetAccountData';
import { reducer as resetOrderDetailDataReducer } from './resetOrderDetailData';
import { reducer as resetInventoryDetailDataReducer } from './resetInventoryDetailData';
import { reducer as deleteAccountDataReducer } from './deleteAccountData';
import { reducer as updateAccountDataReducer } from './updateAccountData';
import { reducer as checkAccountIfExistReducer } from './checkAccountIfExist';
import { reducer as exportAccountsDataReducer } from './exportAccountsData';
import { reducer as uploadAccountsReducer } from './uploadAccounts';
import { reducer as uploadSubstitutionsReducer } from './uploadSubstitutions';
import { reducer as fetchRecommendedProductsReducer } from './fetchRecommendedProducts';
import { reducer as resetRecommendedProductsReducer } from './resetRecommendedProducts';
import { reducer as searchSubstituteProductsReducer } from './searchSubstituteProducts';
import { reducer as resetSubstituteProductsReducer } from './resetSubstituteProducts';
import { reducer as alertsReducer } from './alerts'
import { reducer as removeDropOutsReducer } from './removeDropOuts';
import { reducer as removeAllocatedItemReducer } from './removeAllocatedItem';
import { reducer as allocateOriginalItem  } from './allocateOriginalItem'
import { reducer as checkOutsLockReducer } from './checkOutsLock';
import { reducer as updateOutsRecordLockReducer } from './updateOutsRecordLock';
import { reducer as deleteManageSubsReducer } from './deleteManageSubs';
import { reducer as checkEditSubsLockReducer } from './checkEditSubsLock';
import { reducer as updateEidtSubsLockReducer } from './updateEidtSubsLock';
import { reducer as checkEditAccountLockReducer } from './checkEditAccountLock';
import { reducer as updateEditAccountLockReducer } from './updateEditAccountLock';
import { reducer as fetchSearchPanelOrgInfoReducer } from './fetchSearchPanelOrgInfo';
import { reducer as saveInitTerritoryFiltersReducer } from './saveInitTerritoryFilters';
//merged Melody's code
import { reducer as retainConditionDataReducer } from './retainConditionData';
import { reducer as resetConditionDataReducer } from './resetConditionData';
import { reducer as validateItemInChargeReducer } from './validateItemInCharge';
import { reducer as saveOrderDetailDataReducer } from './saveOrderDetailData';
import { reducer as saveOrgDropdownListReducer } from './saveOrgDropdownList';
import { reducer as fetchEnabledSitesReducer } from './fetchEnabledSites';
import { reducer as saveIsCorpProfileUserReducer } from './saveIsCorpProfileUser';
import { reducer as getSubstitutionsDataReducer } from './getSubstitutionsData';
import { reducer as validateSubItemReducer } from './validateSubItem';
import { reducer as validateAgentReducer } from './validateAgent';
import { reducer as getAllAgentsReducer } from './getAllAgents';
import { reducer as saveAndEmailReducer } from './saveAndEmail';
import { reducer as fetchCustomerGroupDataReducer } from './fetchCustomerGroupData';
import { reducer as resetCustomerGroupDataReducer } from './resetCustomerGroupData';
import { reducer as updateCustomerGroupReducer } from './updateCustomerGroup';
import { reducer as fetchGroupIdsDataReducer } from './fetchGroupIdsData';
import { reducer as resetGroupIdsDataReducer } from './resetGroupIdsData';
import { reducer as fetchValidateGroupReducer } from './fetchValidateGroup';
import { reducer as fetchCustomerDataReducer } from './fetchCustomerData';
import { reducer as validateCustomerReducer } from './validateCustomer';
import { reducer as addCustomersReducer } from './addCustomers';
import { reducer as resetCustomerDataReducer } from './resetCustomerData';
import { reducer as removeCustomerReducer } from './removeCustomer';
import { reducer as uploadGroupsReducer } from './uploadGroups';
import { reducer as exportGroupsDataReducer } from './exportGroupsData';
import { reducer as fetchDropOutsReasonReducer } from './fetchDropOutsReason';
import { reducer as validateActiveConceptCusReducer } from './validateActiveConceptCus';
import { reducer as checkIfExists4thSubRuleReducer } from './checkIfExists4thSubRule';
import { reducer as deleteCustomerGroupReducer } from './deleteCustomerGroup';
import { reducer as fetchAccountsAuditTrailDataReducer } from './fetchAccountsAuditTrailData';
import { reducer as resetAccountsAuditTrailDataReducer } from './resetAccountsAuditTrailData';
import { reducer as removeCustomersReducer } from './removeCustomers';
import { reducer as fetchSubsAuditTrailDataReducer } from './fetchSubsAuditTrailData';
import { reducer as resetSubsAuditTrailDataReducer } from './resetSubsAuditTrailData';

const reducers = [
  fetchSubstitutionsDataReducer,
  resetSubstitutionsDataReducer,
  saveSearchConditionDataReducer,
  fetchValidationItemDataReducer,
  fetchConceptDataReducer,
  fetchOutsDataReducer,
  fetchDropoutsDataReducer,
  resetSearchConditionDataReducer,
  fetchOrderDetailDataReducer,
  fetchInventoryDataReducer,
  resetOutsDataReducer,
  fetchValidationCustomerDataReducer,
  updateSubstitutionsDataReducer,
  exportOutsDataReducer,
  fetchAddedSubsDataReducer,
  resetConceptDataReducer,
  resetValidationItemDataReducer,
  downloadSubstitutionsFileReducer,
  fetchAccountDataReducer,
  resetAccountDataReducer,
  resetOrderDetailDataReducer,
  resetInventoryDetailDataReducer,
  deleteAccountDataReducer,
  updateAccountDataReducer,
  checkAccountIfExistReducer,
  exportAccountsDataReducer,
  uploadAccountsReducer,
  uploadSubstitutionsReducer,
  fetchRecommendedProductsReducer,
  resetRecommendedProductsReducer,
  searchSubstituteProductsReducer,
  resetSubstituteProductsReducer,
  alertsReducer,
  removeDropOutsReducer,
  removeAllocatedItemReducer,
  allocateOriginalItem,
  checkOutsLockReducer,
  updateOutsRecordLockReducer,
  deleteManageSubsReducer,
  resetDropoutsDataReducer,
  checkEditSubsLockReducer,
  updateEidtSubsLockReducer,
  checkEditAccountLockReducer,
  updateEditAccountLockReducer,
  fetchSearchPanelOrgInfoReducer,
  saveInitTerritoryFiltersReducer,
  retainConditionDataReducer,
  resetConditionDataReducer,
  validateItemInChargeReducer,
  saveOrderDetailDataReducer,
  saveOrgDropdownListReducer,
  fetchEnabledSitesReducer,
  saveIsCorpProfileUserReducer,
  getSubstitutionsDataReducer,
  validateSubItemReducer,
  validateAgentReducer,
  getAllAgentsReducer,
  saveAndEmailReducer,
  fetchCustomerGroupDataReducer,
  resetCustomerGroupDataReducer,
  updateCustomerGroupReducer,
  fetchGroupIdsDataReducer,
  resetGroupIdsDataReducer,
  fetchValidateGroupReducer,
  fetchCustomerDataReducer,
  validateCustomerReducer,
  addCustomersReducer,
  resetCustomerDataReducer,
  removeCustomerReducer,
  uploadGroupsReducer,
  exportGroupsDataReducer,
  fetchDropOutsReasonReducer,
  validateActiveConceptCusReducer,
  checkIfExists4thSubRuleReducer,
  deleteCustomerGroupReducer,
  fetchAccountsAuditTrailDataReducer,
  resetAccountsAuditTrailDataReducer,
  removeCustomersReducer,
  fetchSubsAuditTrailDataReducer,
  resetSubsAuditTrailDataReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
