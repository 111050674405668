export const MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_BEGIN = 'MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_FAILURE = 'MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_SUBSTITUTIONS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_SUBSTITUTIONS_DATA = 'MANAGE_SUBS_RESET_SUBSTITUTIONS_DATA';
export const MANAGE_SUBS_SAVE_SEARCH_CONDITION_DATA = 'MANAGE_SUBS_SAVE_SEARCH_CONDITION_DATA';
export const MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_BEGIN = 'MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_FAILURE = 'MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_VALIDATION_ITEM_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_CONCEPT_DATA_BEGIN = 'MANAGE_SUBS_FETCH_CONCEPT_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_CONCEPT_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_CONCEPT_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_CONCEPT_DATA_FAILURE = 'MANAGE_SUBS_FETCH_CONCEPT_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_CONCEPT_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_CONCEPT_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_OUTS_DATA_BEGIN = 'MANAGE_SUBS_FETCH_OUTS_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_OUTS_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_OUTS_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_OUTS_DATA_FAILURE = 'MANAGE_SUBS_FETCH_OUTS_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_OUTS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_OUTS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_DROPOUTS_DATA_BEGIN = 'MANAGE_SUBS_FETCH_DROPOUTS_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_DROPOUTS_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_DROPOUTS_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_DROPOUTS_DATA_FAILURE = 'MANAGE_SUBS_FETCH_DROPOUTS_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_DROPOUTS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_DROPOUTS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_SEARCH_CONDITION_DATA = 'MANAGE_SUBS_RESET_SEARCH_CONDITION_DATA';
export const MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_BEGIN = 'MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_FAILURE = 'MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_ORDER_DETAIL_DATA_DISMISS_ERROR';

export const MANAGE_SUBS_FETCH_INVENTORY_DATA_BEGIN = 'MANAGE_SUBS_FETCH_INVENTORY_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_INVENTORY_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_INVENTORY_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_INVENTORY_DATA_FAILURE = 'MANAGE_SUBS_FETCH_INVENTORY_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_INVENTORY_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_INVENTORY_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_OUTS_DATA = 'MANAGE_SUBS_RESET_OUTS_DATA';
export const MANAGE_SUBS_RESET_DROPOUTS_DATA = 'MANAGE_SUBS_RESET_DROPOUTS_DATA';
export const MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_BEGIN = 'MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_FAILURE = 'MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_VALIDATION_CUSTOMER_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_BEGIN = 'MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_BEGIN';
export const MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_SUCCESS = 'MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_SUCCESS';
export const MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_FAILURE = 'MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_FAILURE';
export const MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_SUBSTITUTIONS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_BEGIN='MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_BEGIN';
export const MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_SUCCESS='MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_SUCCESS';
export const MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_FAILURE='MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_FAILURE';
export const MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_DISMISS_ERROR='MANAGE_SUBS_EXPORT_OUTS_DATA_FILE_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_BEGIN = 'MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_FAILURE = 'MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_ADDED_SUBS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_CONCEPT_DATA = 'MANAGE_SUBS_RESET_CONCEPT_DATA';
export const MANAGE_SUBS_RESET_VALIDATION_ITEM_DATA = 'MANAGE_SUBS_RESET_VALIDATION_ITEM_DATA';
export const MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_BEGIN = 'MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_BEGIN';
export const MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_SUCCESS = 'MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_SUCCESS';
export const MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_FAILURE = 'MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_FAILURE';
export const MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_DISMISS_ERROR = 'MANAGE_SUBS_DOWNLOAD_SUBSTITUTIONS_FILE_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_ACCOUNT_DATA_BEGIN = 'MANAGE_SUBS_FETCH_ACCOUNT_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_ACCOUNT_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_ACCOUNT_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_ACCOUNT_DATA_FAILURE = 'MANAGE_SUBS_FETCH_ACCOUNT_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_ACCOUNT_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_ACCOUNT_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_ACCOUNT_DATA = 'MANAGE_SUBS_RESET_ACCOUNT_DATA';
// export const MANAGE_SUBS_UPADTE_ACCOUNT_DATA_BEGIN = 'MANAGE_SUBS_UPADTE_ACCOUNT_DATA_BEGIN';
// export const MANAGE_SUBS_UPADTE_ACCOUNT_DATA_SUCCESS = 'MANAGE_SUBS_UPADTE_ACCOUNT_DATA_SUCCESS';
// export const MANAGE_SUBS_UPADTE_ACCOUNT_DATA_FAILURE = 'MANAGE_SUBS_UPADTE_ACCOUNT_DATA_FAILURE';
// export const MANAGE_SUBS_UPADTE_ACCOUNT_DATA_DISMISS_ERROR = 'MANAGE_SUBS_UPADTE_ACCOUNT_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_ORDER_DETAIL_DATA = 'MANAGE_SUBS_RESET_ORDER_DETAIL_DATA';
export const MANAGE_SUBS_RESET_INVENTORY_DETAIL_DATA = 'MANAGE_SUBS_RESET_INVENTORY_DETAIL_DATA';
export const MANAGE_SUBS_DELETE_ACCOUNT_DATA_BEGIN = 'MANAGE_SUBS_DELETE_ACCOUNT_DATA_BEGIN';
export const MANAGE_SUBS_DELETE_ACCOUNT_DATA_SUCCESS = 'MANAGE_SUBS_DELETE_ACCOUNT_DATA_SUCCESS';
export const MANAGE_SUBS_DELETE_ACCOUNT_DATA_FAILURE = 'MANAGE_SUBS_DELETE_ACCOUNT_DATA_FAILURE';
export const MANAGE_SUBS_DELETE_ACCOUNT_DATA_DISMISS_ERROR = 'MANAGE_SUBS_DELETE_ACCOUNT_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_UPDATE_ACCOUNT_DATA_BEGIN = 'MANAGE_SUBS_UPDATE_ACCOUNT_DATA_BEGIN';
export const MANAGE_SUBS_UPDATE_ACCOUNT_DATA_SUCCESS = 'MANAGE_SUBS_UPDATE_ACCOUNT_DATA_SUCCESS';
export const MANAGE_SUBS_UPDATE_ACCOUNT_DATA_FAILURE = 'MANAGE_SUBS_UPDATE_ACCOUNT_DATA_FAILURE';
export const MANAGE_SUBS_UPDATE_ACCOUNT_DATA_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_ACCOUNT_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_BEGIN = 'MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_BEGIN';
export const MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_SUCCESS = 'MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_SUCCESS';
export const MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_FAILURE = 'MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_FAILURE';
export const MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_DISMISS_ERROR = 'MANAGE_SUBS_CHECK_ACCOUNT_IF_EXIST_DISMISS_ERROR';
export const MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_BEGIN = 'MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_BEGIN';
export const MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_SUCCESS = 'MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_SUCCESS';
export const MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_FAILURE = 'MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_FAILURE';
export const MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_EXPORT_ACCOUNTS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_UPLOAD_ACCOUNTS_BEGIN = 'MANAGE_SUBS_UPLOAD_ACCOUNTS_BEGIN';
export const MANAGE_SUBS_UPLOAD_ACCOUNTS_SUCCESS = 'MANAGE_SUBS_UPLOAD_ACCOUNTS_SUCCESS';
export const MANAGE_SUBS_UPLOAD_ACCOUNTS_FAILURE = 'MANAGE_SUBS_UPLOAD_ACCOUNTS_FAILURE';
export const MANAGE_SUBS_UPLOAD_ACCOUNTS_DISMISS_ERROR = 'MANAGE_SUBS_UPLOAD_ACCOUNTS_DISMISS_ERROR';
export const MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_BEGIN = 'MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_BEGIN';
export const MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_SUCCESS = 'MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_SUCCESS';
export const MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_FAILURE = 'MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_FAILURE';
export const MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_DISMISS_ERROR = 'MANAGE_SUBS_UPLOAD_SUBSTITUTIONS_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_BEGIN = 'MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_BEGIN';
export const MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_SUCCESS = 'MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_SUCCESS';
export const MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE = 'MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_FAILURE';
export const MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_RECOMMENDED_PRODUCTS_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_RECOMMENDED_PRODUCTS = 'MANAGE_SUBS_RESET_RECOMMENDED_PRODUCTS';
export const MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_BEGIN = 'MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_BEGIN';
export const MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_SUCCESS = 'MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_SUCCESS';
export const MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_FAILURE = 'MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_FAILURE';
export const MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_DISMISS_ERROR = 'MANAGE_SUBS_SEARCH_SUBSTITUTE_PRODUCTS_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_SUBSTITUTE_PRODUCTS = 'MANAGE_SUBS_RESET_SUBSTITUTE_PRODUCTS';
export const MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_BEGIN = 'MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_BEGIN';
export const MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_SUCCESS = 'MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_SUCCESS';
export const MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_FAILURE = 'MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_FAILURE';
export const MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_DISMISS_ERROR = 'MANAGE_SUBS_ALLOCATE_ORIGINAL_ITEM_DISMISS_ERROR';
export const MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_BEGIN = 'MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_BEGIN';
export const MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_SUCCESS = 'MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_SUCCESS';
export const MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_FAILURE = 'MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_FAILURE';
export const MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_DISMISS_ERROR = 'MANAGE_SUBS_REMOVE_ALLOCATED_ITEM_DISMISS_ERROR';
export const MANAGE_SUBS_REMOVE_DROP_OUTS_BEGIN = 'MANAGE_SUBS_REMOVE_DROP_OUTS_BEGIN';
export const MANAGE_SUBS_REMOVE_DROP_OUTS_SUCCESS = 'MANAGE_SUBS_REMOVE_DROP_OUTS_SUCCESS';
export const MANAGE_SUBS_REMOVE_DROP_OUTS_FAILURE = 'MANAGE_SUBS_REMOVE_DROP_OUTS_FAILURE';
export const MANAGE_SUBS_REMOVE_DROP_OUTS_DISMISS_ERROR = 'MANAGE_SUBS_REMOVE_DROP_OUTS_DISMISS_ERROR';
export const MANAGE_SUBS_ALERTS_BEGIN = 'MANAGE_SUBS_ALERTS_BEGIN';
export const MANAGE_SUBS_ALERTS_SUCCESS = 'MANAGE_SUBS_ALERTS_SUCCESS';
export const MANAGE_SUBS_ALERTS_FAILURE = 'MANAGE_SUBS_ALERTS_FAILURE';
export const MANAGE_SUBS_ALERTS_DISMISS_ERROR = 'MANAGE_SUBS_ALERTS_DISMISS_ERROR';
export const MANAGE_SUBS_CHECK_OUTS_LOCK_BEGIN = 'MANAGE_SUBS_CHECK_OUTS_LOCK_BEGIN';
export const MANAGE_SUBS_CHECK_OUTS_LOCK_SUCCESS = 'MANAGE_SUBS_CHECK_OUTS_LOCK_SUCCESS';
export const MANAGE_SUBS_CHECK_OUTS_LOCK_FAILURE = 'MANAGE_SUBS_CHECK_OUTS_LOCK_FAILURE';
export const MANAGE_SUBS_CHECK_OUTS_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_CHECK_OUTS_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_BEGIN = 'MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_BEGIN';
export const MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_SUCCESS = 'MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_SUCCESS';
export const MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_FAILURE = 'MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_FAILURE';
export const MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_OUTS_RECORD_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_DELETE_MANAGE_SUBS_BEGIN = 'MANAGE_SUBS_DELETE_MANAGE_SUBS_BEGIN';
export const MANAGE_SUBS_DELETE_MANAGE_SUBS_SUCCESS = 'MANAGE_SUBS_DELETE_MANAGE_SUBS_SUCCESS';
export const MANAGE_SUBS_DELETE_MANAGE_SUBS_FAILURE = 'MANAGE_SUBS_DELETE_MANAGE_SUBS_FAILURE';
export const MANAGE_SUBS_DELETE_MANAGE_SUBS_DISMISS_ERROR = 'MANAGE_SUBS_DELETE_MANAGE_SUBS_DISMISS_ERROR';
export const MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_BEGIN = 'MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_BEGIN';
export const MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_SUCCESS = 'MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_SUCCESS';
export const MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_FAILURE = 'MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_FAILURE';
export const MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_CHECK_EDIT_SUBS_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_BEGIN = 'MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_BEGIN';
export const MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_SUCCESS = 'MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_SUCCESS';
export const MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_FAILURE = 'MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_FAILURE';
export const MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_EIDT_SUBS_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_BEGIN = 'MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_BEGIN';
export const MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_SUCCESS = 'MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_SUCCESS';
export const MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_FAILURE = 'MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_FAILURE';
export const MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_CHECK_EDIT_ACCOUNT_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_BEGIN = 'MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_BEGIN';
export const MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_SUCCESS = 'MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_SUCCESS';
export const MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_FAILURE = 'MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_FAILURE';
export const MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_EDIT_ACCOUNT_LOCK_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_BEGIN = 'MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_BEGIN';
export const MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_SUCCESS = 'MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_SUCCESS';
export const MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE = 'MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_FAILURE';
export const MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_SEARCH_PANEL_ORG_INFO_DISMISS_ERROR';
export const MANAGE_SUBS_SAVE_INIT_TERRITORY_FILTERS = 'MANAGE_SUBS_SAVE_INIT_TERRITORY_FILTERS';
//merged Melody's code
export const MANAGE_SUBS_RETAIN_CONDITION_DATA = 'MANAGE_SUBS_RETAIN_CONDITION_DATA';
export const MANAGE_SUBS_RESET_CONDITION_DATA = 'MANAGE_SUBS_RESET_CONDITION_DATA';
export const MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_BEGIN = 'MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_BEGIN';
export const MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_SUCCESS = 'MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_SUCCESS';
export const MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE = 'MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_FAILURE';
export const MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_DISMISS_ERROR = 'MANAGE_SUBS_VALIDATE_ITEM_IN_CHARGE_DISMISS_ERROR';
export const MANAGE_SUBS_SAVE_ORDER_DETAIL_DATA = 'MANAGE_SUBS_SAVE_ORDER_DETAIL_DATA';
export const MANAGE_SUBS_SAVE_ORG_DROPDOWN_LIST = 'MANAGE_SUBS_SAVE_ORG_DROPDOWN_LIST';
export const MANAGE_SUBS_FETCH_ENABLED_SITES_BEGIN = 'MANAGE_SUBS_FETCH_ENABLED_SITES_BEGIN';
export const MANAGE_SUBS_FETCH_ENABLED_SITES_SUCCESS = 'MANAGE_SUBS_FETCH_ENABLED_SITES_SUCCESS';
export const MANAGE_SUBS_FETCH_ENABLED_SITES_FAILURE = 'MANAGE_SUBS_FETCH_ENABLED_SITES_FAILURE';
export const MANAGE_SUBS_FETCH_ENABLED_SITES_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_ENABLED_SITES_DISMISS_ERROR';
export const MANAGE_SUBS_SAVE_IS_CORP_PROFILE_USER = 'MANAGE_SUBS_SAVE_IS_CORP_PROFILE_USER';
export const MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_BEGIN = 'MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_BEGIN';
export const MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_SUCCESS = 'MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_SUCCESS';
export const MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_FAILURE = 'MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_FAILURE';
export const MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_GET_SUBSTITUTIONS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_VALIDATE_SUB_ITEM_BEGIN = 'MANAGE_SUBS_VALIDATE_SUB_ITEM_BEGIN';
export const MANAGE_SUBS_VALIDATE_SUB_ITEM_SUCCESS = 'MANAGE_SUBS_VALIDATE_SUB_ITEM_SUCCESS';
export const MANAGE_SUBS_VALIDATE_SUB_ITEM_FAILURE = 'MANAGE_SUBS_VALIDATE_SUB_ITEM_FAILURE';
export const MANAGE_SUBS_VALIDATE_SUB_ITEM_DISMISS_ERROR = 'MANAGE_SUBS_VALIDATE_SUB_ITEM_DISMISS_ERROR';
export const MANAGE_SUBS_VALIDATE_AGENT_BEGIN = 'MANAGE_SUBS_VALIDATE_AGENT_BEGIN';
export const MANAGE_SUBS_VALIDATE_AGENT_SUCCESS = 'MANAGE_SUBS_VALIDATE_AGENT_SUCCESS';
export const MANAGE_SUBS_VALIDATE_AGENT_FAILURE = 'MANAGE_SUBS_VALIDATE_AGENT_FAILURE';
export const MANAGE_SUBS_VALIDATE_AGENT_DISMISS_ERROR = 'MANAGE_SUBS_VALIDATE_AGENT_DISMISS_ERROR';
export const MANAGE_SUBS_GET_ALL_AGENTS_BEGIN = 'MANAGE_SUBS_GET_ALL_AGENTS_BEGIN';
export const MANAGE_SUBS_GET_ALL_AGENTS_SUCCESS = 'MANAGE_SUBS_GET_ALL_AGENTS_SUCCESS';
export const MANAGE_SUBS_GET_ALL_AGENTS_FAILURE = 'MANAGE_SUBS_GET_ALL_AGENTS_FAILURE';
export const MANAGE_SUBS_GET_ALL_AGENTS_DISMISS_ERROR = 'MANAGE_SUBS_GET_ALL_AGENTS_DISMISS_ERROR';
export const MANAGE_SUBS_SAVE_AND_EMAIL_BEGIN = 'MANAGE_SUBS_SAVE_AND_EMAIL_BEGIN';
export const MANAGE_SUBS_SAVE_AND_EMAIL_SUCCESS = 'MANAGE_SUBS_SAVE_AND_EMAIL_SUCCESS';
export const MANAGE_SUBS_SAVE_AND_EMAIL_FAILURE = 'MANAGE_SUBS_SAVE_AND_EMAIL_FAILURE';
export const MANAGE_SUBS_SAVE_AND_EMAIL_DISMISS_ERROR = 'MANAGE_SUBS_SAVE_AND_EMAIL_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_BEGIN = 'MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_FAILURE = 'MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_CUSTOMER_GROUP_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_CUSTOMER_GROUP_DATA = 'MANAGE_SUBS_RESET_CUSTOMER_GROUP_DATA';
export const MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_BEGIN = 'MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_BEGIN';
export const MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_SUCCESS = 'MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_SUCCESS';
export const MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_FAILURE = 'MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_FAILURE';
export const MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_DISMISS_ERROR = 'MANAGE_SUBS_UPDATE_CUSTOMER_GROUP_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_GROUP_IDS_DATA_BEGIN = 'MANAGE_SUBS_FETCH_GROUP_IDS_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_GROUP_IDS_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_GROUP_IDS_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_GROUP_IDS_DATA_FAILURE = 'MANAGE_SUBS_FETCH_GROUP_IDS_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_GROUP_IDS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_GROUP_IDS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_GROUP_IDS_DATA = 'MANAGE_SUBS_RESET_GROUP_IDS_DATA';
export const MANAGE_SUBS_FETCH_VALIDATE_GROUP_BEGIN = 'MANAGE_SUBS_FETCH_VALIDATE_GROUP_BEGIN';
export const MANAGE_SUBS_FETCH_VALIDATE_GROUP_SUCCESS = 'MANAGE_SUBS_FETCH_VALIDATE_GROUP_SUCCESS';
export const MANAGE_SUBS_FETCH_VALIDATE_GROUP_FAILURE = 'MANAGE_SUBS_FETCH_VALIDATE_GROUP_FAILURE';
export const MANAGE_SUBS_FETCH_VALIDATE_GROUP_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_VALIDATE_GROUP_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_CUSTOMER_DATA_BEGIN = 'MANAGE_SUBS_FETCH_CUSTOMER_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_CUSTOMER_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_CUSTOMER_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_CUSTOMER_DATA_FAILURE = 'MANAGE_SUBS_FETCH_CUSTOMER_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_CUSTOMER_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_CUSTOMER_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_VALIDATE_CUSTOMER_BEGIN = 'MANAGE_SUBS_VALIDATE_CUSTOMER_BEGIN';
export const MANAGE_SUBS_VALIDATE_CUSTOMER_SUCCESS = 'MANAGE_SUBS_VALIDATE_CUSTOMER_SUCCESS';
export const MANAGE_SUBS_VALIDATE_CUSTOMER_FAILURE = 'MANAGE_SUBS_VALIDATE_CUSTOMER_FAILURE';
export const MANAGE_SUBS_VALIDATE_CUSTOMER_DISMISS_ERROR = 'MANAGE_SUBS_VALIDATE_CUSTOMER_DISMISS_ERROR';
export const MANAGE_SUBS_ADD_CUSTOMERS_BEGIN = 'MANAGE_SUBS_ADD_CUSTOMERS_BEGIN';
export const MANAGE_SUBS_ADD_CUSTOMERS_SUCCESS = 'MANAGE_SUBS_ADD_CUSTOMERS_SUCCESS';
export const MANAGE_SUBS_ADD_CUSTOMERS_FAILURE = 'MANAGE_SUBS_ADD_CUSTOMERS_FAILURE';
export const MANAGE_SUBS_ADD_CUSTOMERS_DISMISS_ERROR = 'MANAGE_SUBS_ADD_CUSTOMERS_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_CUSTOMER_DATA = 'MANAGE_SUBS_RESET_CUSTOMER_DATA';
export const MANAGE_SUBS_REMOVE_CUSTOMER_BEGIN = 'MANAGE_SUBS_REMOVE_CUSTOMER_BEGIN';
export const MANAGE_SUBS_REMOVE_CUSTOMER_SUCCESS = 'MANAGE_SUBS_REMOVE_CUSTOMER_SUCCESS';
export const MANAGE_SUBS_REMOVE_CUSTOMER_FAILURE = 'MANAGE_SUBS_REMOVE_CUSTOMER_FAILURE';
export const MANAGE_SUBS_REMOVE_CUSTOMER_DISMISS_ERROR = 'MANAGE_SUBS_REMOVE_CUSTOMER_DISMISS_ERROR';
export const MANAGE_SUBS_UPLOAD_GROUPS_BEGIN = 'MANAGE_SUBS_UPLOAD_GROUPS_BEGIN';
export const MANAGE_SUBS_UPLOAD_GROUPS_SUCCESS = 'MANAGE_SUBS_UPLOAD_GROUPS_SUCCESS';
export const MANAGE_SUBS_UPLOAD_GROUPS_FAILURE = 'MANAGE_SUBS_UPLOAD_GROUPS_FAILURE';
export const MANAGE_SUBS_UPLOAD_GROUPS_DISMISS_ERROR = 'MANAGE_SUBS_UPLOAD_GROUPS_DISMISS_ERROR';
export const MANAGE_SUBS_EXPORT_GROUPS_DATA_BEGIN = 'MANAGE_SUBS_EXPORT_GROUPS_DATA_BEGIN';
export const MANAGE_SUBS_EXPORT_GROUPS_DATA_SUCCESS = 'MANAGE_SUBS_EXPORT_GROUPS_DATA_SUCCESS';
export const MANAGE_SUBS_EXPORT_GROUPS_DATA_FAILURE = 'MANAGE_SUBS_EXPORT_GROUPS_DATA_FAILURE';
export const MANAGE_SUBS_EXPORT_GROUPS_DATA_DISMISS_ERROR = 'MANAGE_SUBS_EXPORT_GROUPS_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_DROP_OUTS_REASON_BEGIN = 'MANAGE_SUBS_FETCH_DROP_OUTS_REASON_BEGIN';
export const MANAGE_SUBS_FETCH_DROP_OUTS_REASON_SUCCESS = 'MANAGE_SUBS_FETCH_DROP_OUTS_REASON_SUCCESS';
export const MANAGE_SUBS_FETCH_DROP_OUTS_REASON_FAILURE = 'MANAGE_SUBS_FETCH_DROP_OUTS_REASON_FAILURE';
export const MANAGE_SUBS_FETCH_DROP_OUTS_REASON_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_DROP_OUTS_REASON_DISMISS_ERROR';

export const MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_BEGIN = 'MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_BEGIN';
export const MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_SUCCESS = 'MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_SUCCESS';
export const MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_FAILURE = 'MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_FAILURE';
export const MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_DISMISS_ERROR = 'MANAGE_SUBS_VALIDATE_ACTIVE_CONCEPT_CUS_DISMISS_ERROR';

export const MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_BEGIN = 'MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_BEGIN';
export const MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_SUCCESS = 'MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_SUCCESS';
export const MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_FAILURE = 'MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_FAILURE';
export const MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_DISMISS_ERROR = 'MANAGE_SUBS_CHECK_IF_EXISTS_4TH_SUB_RULE_DISMISS_ERROR';

export const MANAGE_SUBS_DELETE_CUSTOMER_GROUP_BEGIN = 'MANAGE_SUBS_DELETE_CUSTOMER_GROUP_BEGIN';
export const MANAGE_SUBS_DELETE_CUSTOMER_GROUP_SUCCESS = 'MANAGE_SUBS_DELETE_CUSTOMER_GROUP_SUCCESS';
export const MANAGE_SUBS_DELETE_CUSTOMER_GROUP_FAILURE = 'MANAGE_SUBS_DELETE_CUSTOMER_GROUP_FAILURE';
export const MANAGE_SUBS_DELETE_CUSTOMER_GROUP_DISMISS_ERROR = 'MANAGE_SUBS_DELETE_CUSTOMER_GROUP_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_BEGIN = 'MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_FAILURE = 'MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_ACCOUNTS_AUDIT_TRAIL_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_ACCOUNTS_AUDIT_TRAIL_DATA = 'MANAGE_SUBS_RESET_ACCOUNTS_AUDIT_TRAIL_DATA';
export const MANAGE_SUBS_UPDATE_CUSTOMER_LIST = 'MANAGE_SUBS_UPDATE_CUSTOMER_LIST';
export const MANAGE_SUBS_REMOVE_CUSTOMERS_BEGIN = 'MANAGE_SUBS_REMOVE_CUSTOMERS_BEGIN';
export const MANAGE_SUBS_REMOVE_CUSTOMERS_SUCCESS = 'MANAGE_SUBS_REMOVE_CUSTOMERS_SUCCESS';
export const MANAGE_SUBS_REMOVE_CUSTOMERS_FAILURE = 'MANAGE_SUBS_REMOVE_CUSTOMERS_FAILURE';
export const MANAGE_SUBS_REMOVE_CUSTOMERS_DISMISS_ERROR = 'MANAGE_SUBS_REMOVE_CUSTOMERS_DISMISS_ERROR';
export const MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_BEGIN = 'MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_BEGIN';
export const MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_SUCCESS = 'MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_SUCCESS';
export const MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_FAILURE = 'MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_FAILURE';
export const MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_DISMISS_ERROR = 'MANAGE_SUBS_FETCH_SUBS_AUDIT_TRAIL_DATA_DISMISS_ERROR';
export const MANAGE_SUBS_RESET_SUBS_AUDIT_TRAIL_DATA = 'MANAGE_SUBS_RESET_SUBS_AUDIT_TRAIL_DATA';
