export const CONST_CUSTOMER_GROUP_TABLE_COLUMNS = [
  {
    title: 'Site ID',
    dataIndex: 'site',
    width: 100,
    blankWidth: 100,
    // sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Group ID',
    dataIndex: 'groupId',
    width: 100,
    blankWidth: 100,
    // sorter: true,
    render: false,
  },
  {
    title: 'Group Description/User ID',
    dataIndex: 'groupDescription',
    width: 160,
    blankWidth: 160,
    render: false,
    onCell: (record, rowIndex) => {
        return {
          style: {
            wordBreak: 'break-word',
          }
        }
    },

    extendRender: true,
  },
  {
    title: 'Number of Customer',
    dataIndex: 'customerCount',
    width: 120,
    blankWidth: 120,
    render: false,
  },
  {
    title: 'Modified',
    dataIndex: 'modifiedByName',
    width: 140,
    blankWidth: 140,
    render: false,
    extendRender: true,
  },
];
export const GROUP_ID_PREFIX = "##";
export const CUSTOMER_LIST_COLUMNS = [
  {
    title: 'Customer Number',
    dataIndex: 'customerId',
    width: 150,
    blankWidth: 100,
    // sorter: true,
    render: false,
    extendRender: true,
  },
  {
    title: 'Type',
    dataIndex: 'accountType',
    width: 80,
    blankWidth: 60,
    render: false,
  },
  {
    title: 'Substituted',
    dataIndex: 'substitutedFlag',
    width: 110,
    blankWidth: 90,
    render: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 80,
    blankWidth: 60,
    render: false,
  },
  {
    title: 'Modified',
    dataIndex: 'modifiedByName',
    width: 120,
    blankWidth: 100,
    render: false,
    extendRender: true,
  },
]