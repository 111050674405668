export const CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: 80,
      blankWidth: 80,
      sorter: false,
      render: false,
    },
    {
      title: 'Account#',
      dataIndex: 'nationalId',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
      extendRender: true,
    },
    {
      title: 'Log',
      dataIndex: 'log',
      width: 130,
      blankWidth: 130,
      sorter: false,
      render: false,
    },
    {
      title: 'Value Before Change',
      dataIndex: 'oldValue',
      width: 145,
      blankWidth: 145,
      sorter: false,
      render: false,
    },
    {
      title: 'Value After Change',
      dataIndex: 'newValue',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified by Id',
      dataIndex: 'userId',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifiedDate',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Timestamp',
      dataIndex: 'modifiedTimestamp',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
  ];
  export const CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS = [
    {
      title: 'Site',
      dataIndex: 'site',
      width: 70,
      blankWidth: 70,
      sorter: false,
      render: false,
    },
    {
      title: 'Account#',
      dataIndex: 'nationalId',
      width: 100,
      blankWidth: 100,
      sorter: false,
      render: false,
      extendRender: true,
    },
    {
      title: 'Original Item',
      dataIndex: 'originalItem',
      width: 120,
      blankWidth: 80,
      sorter: false,
      render: false,
    },
    {
      title: 'Log',
      dataIndex: 'log',
      width: 130,
      blankWidth: 130,
      sorter: false,
      render: false,
    },
    {
      title: 'Value Before Change',
      dataIndex: 'oldValue',
      width: 145,
      blankWidth: 145,
      sorter: false,
      render: false,
    },
    {
      title: 'Value After Change',
      dataIndex: 'newValue',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified by Id',
      dataIndex: 'userId',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Date',
      dataIndex: 'modifiedDate',
      width: 120,
      blankWidth: 120,
      sorter: false,
      render: false,
    },
    {
      title: 'Modified Timestamp',
      dataIndex: 'modifiedTimestamp',
      width: 140,
      blankWidth: 140,
      sorter: false,
      render: false,
    },
  ];