import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CommonTable } from '../common';
import { CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS, CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS } from '../../common/manageAccountsAuditTrailConstants';
import { CONST_PAGINATION_TEN } from '../../common/constants';
import { useFetchSubsAuditTrailData,useResetSubsAuditTrailData } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';

export default function SubRepositoriesAuditTrail() {
  const {
    subsAuditTrailData,
    fetchSubsAuditTrailDataPending,
    searchConditionData, 
  } = useSelector(state => ({
    subsAuditTrailData: state.manageSubs.subsAuditTrailData,
    fetchSubsAuditTrailDataPending: state.manageSubs.fetchSubsAuditTrailDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
  }));
  const { fetchSubsAuditTrailData }  = useFetchSubsAuditTrailData();
  const { resetSubsAuditTrailData } = useResetSubsAuditTrailData();
  const renderColumnsElement = [
    {},//site placeholder
    { //render Account#
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              {value}
            </>
          )
        }
        return (
          <>
            {record.customerNumber}
          </>
        )
      }
    },
    {},//originalItem placeholder
    {},//log placeholder
    {},//oldValue placeholder
    {},//newValue placeholder
    {},//userId placeholder
    {},//modifiedDate placeholder
    {},//modifiedTimestamp placeholder
  ]
  let columns = renderTableColumns(CONST_SUBS_AUDIT_TRAIL_TABLE_COLUMNS, null, renderColumnsElement);
  return (
    <div className="manage-subs-sub-repositories-audit-trail">
       <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Sub Repositories Audit Trail</div>
      <SearchFiltersPanel />
      <div className="flex align-item-center justify-end" style={{marginBottom:"20px"}}></div>
      <CommonTable 
        rowKey={Math.random()}
        columns={columns}
        tableClassName={subsAuditTrailData ? "outs-report-table" : ""}
        datasource={subsAuditTrailData}
        loadingData={fetchSubsAuditTrailDataPending}
        paginationStyle={{textAlign: "right", margin: '16px 0px'}}
        triggerConditions={searchConditionData}
        onFecthData={fetchSubsAuditTrailData}
        onResetStore={resetSubsAuditTrailData}
        scrollStyle={{ x: 'max-content', y: 300 }}
        paginationSetting={CONST_PAGINATION_TEN}
      />
    </div>
  );
};

SubRepositoriesAuditTrail.propTypes = {};
SubRepositoriesAuditTrail.defaultProps = {};
