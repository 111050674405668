import moment from 'moment';
import { sortString } from './sorterFunc';

export const CONST_PLAIN_OPTION = [{ label: 'EVENT ID', value: 'EVENT ID' }];

export const CONST_SITE_LIST = [
  { opcoNo: '001', opcoName: 'Jackson' },
  { opcoNo: '002', opcoName: 'Atlanta' },
  { opcoNo: '003', opcoName: 'Jacksonville' },
  { opcoNo: '004', opcoName: 'Central California' },
  { opcoNo: '005', opcoName: 'Intermountain' },
  { opcoNo: '006', opcoName: 'Sysco North Texas' },
  { opcoNo: '007', opcoName: 'Virginia' },
  { opcoNo: '008', opcoName: 'North New England' },
  { opcoNo: '009', opcoName: 'Pittsburgh' },
  { opcoNo: '010', opcoName: 'Eastern Maryland' },
  { opcoNo: '011', opcoName: 'Louisville' },
  { opcoNo: '012', opcoName: 'Baltimore' },
  { opcoNo: '013', opcoName: 'Central Texas' },
  { opcoNo: '014', opcoName: 'Memphis' },
  { opcoNo: '015', opcoName: 'Cleveland' },
  { opcoNo: '016', opcoName: 'South Florida' },
  { opcoNo: '017', opcoName: 'Las Vegas' },
  { opcoNo: '018', opcoName: 'Baraboo' },
  { opcoNo: '019', opcoName: 'Cincinnati' },
  { opcoNo: '022', opcoName: 'Central Florida' },
  { opcoNo: '023', opcoName: 'New Orleans' },
  { opcoNo: '024', opcoName: 'Chicago' },
  { opcoNo: '025', opcoName: 'Albany' },
  { opcoNo: '026', opcoName: 'Sysco Oklahoma' },
  { opcoNo: '027', opcoName: 'Syracuse' },
  { opcoNo: '029', opcoName: 'Arkansas' },
  { opcoNo: '031', opcoName: 'Sacramento' },
  { opcoNo: '032', opcoName: 'SE Florida' },
  { opcoNo: '035', opcoName: 'East Wisconsin' },
  { opcoNo: '036', opcoName: 'San Diego' },
  { opcoNo: '037', opcoName: 'West Coast Florida' },
  { opcoNo: '038', opcoName: 'Indianapolis' },
  { opcoNo: '039', opcoName: 'Iowa' },
  { opcoNo: '040', opcoName: 'Sysco Idaho' },
  { opcoNo: '042', opcoName: 'Central Warehouse' },
  { opcoNo: '043', opcoName: 'Montana' },
  { opcoNo: '045', opcoName: 'Los Angeles' },
  { opcoNo: '046', opcoName: 'Central Alabama' },
  { opcoNo: '047', opcoName: 'Minnesota' },
  { opcoNo: '048', opcoName: 'Charlotte' },
  { opcoNo: '049', opcoName: 'Sysco Arizona' },
  { opcoNo: '050', opcoName: 'San Francisco' },
  { opcoNo: '051', opcoName: 'Central PA' },
  { opcoNo: '052', opcoName: 'Portland' },
  { opcoNo: '054', opcoName: 'Connecticut' },
  { opcoNo: '055', opcoName: 'Seattle' },
  { opcoNo: '056', opcoName: 'Boston' },
  { opcoNo: '057', opcoName: 'Kansas City' },
  { opcoNo: '058', opcoName: 'Detroit' },
  { opcoNo: '059', opcoName: 'Sysco Denver' },
  { opcoNo: '060', opcoName: 'Nashville' },
  { opcoNo: '061', opcoName: 'Lincoln' },
  { opcoNo: '064', opcoName: 'St Louis' },
  { opcoNo: '066', opcoName: 'Sysco New Mexico' },
  { opcoNo: '067', opcoName: 'Houston' },
  { opcoNo: '068', opcoName: 'Grand Rapids' },
  { opcoNo: '073', opcoName: 'Hampton Roads' },
  { opcoNo: '075', opcoName: 'Philadelphia' },
  { opcoNo: '076', opcoName: 'Metro New York' },
  { opcoNo: '078', opcoName: 'West Texas' },
  { opcoNo: '101', opcoName: 'Ventura' },
  { opcoNo: '102', opcoName: 'Spokane' },
  { opcoNo: '134', opcoName: 'IFG - Plant City' },
  { opcoNo: '137', opcoName: 'Columbia' },
  { opcoNo: '146', opcoName: 'Reno Central Whse' },
  { opcoNo: '147', opcoName: 'Central Warehouse-SouthEast' },
  { opcoNo: '148', opcoName: 'NE Central Warehouse' },
  { opcoNo: '163', opcoName: 'Raleigh' },
  { opcoNo: '164', opcoName: 'Gulf Coast' },
  { opcoNo: '177', opcoName: 'Northeast RDC' },
  { opcoNo: '184', opcoName: 'Southeast RDC' },
  { opcoNo: '194', opcoName: 'Central Illinois' },
  { opcoNo: '195', opcoName: 'North Dakota' },
  { opcoNo: '288', opcoName: 'Knoxville' },
  { opcoNo: '293', opcoName: 'Sysco East Texas' },
  { opcoNo: '306', opcoName: 'Long Island' },
  { opcoNo: '320', opcoName: 'Riverside' },
  { opcoNo: '332', opcoName: 'Western Minnesota' },
  { opcoNo: '335', opcoName: 'Bahama Food Services' },
  { opcoNo: '344', opcoName: 'IFG - Jacksonville' },
  { opcoNo: '429', opcoName: 'Doerle' },
  { opcoNo: '450', opcoName: 'Alaska' },
  { opcoNo: '629', opcoName: 'Allentown' },
  { opcoNo: '653', opcoName: 'Tampa' },
  { opcoNo: '654', opcoName: 'Arizona East' },
];

export const CONST_ORDER_TRAIL_COLUMNS = {
  key: 'Orders',
  columns: [
    {
      title: 'Site',
      dataIndex: 'opco',
      width: null,
      blankWidth: 80,
      sorter: (a, b) => sortString(a.opco, b.opco),
      render: false,
    },
    {
      title: 'Order #',
      dataIndex: 'orderNo',
      width: 100,
      blankWidth: 100,
      sorter: (a, b) => sortString(a.orderNo, b.orderNo),
      render: false,
    },
    {
      title: 'Shop #',
      dataIndex: 'uomOrderNo',
      width: 100,
      blankWidth: 100,
      sorter: (a, b) => sortString(a.uomOrderNo, b.uomOrderNo),
      render: false,
    },
    {
      title: 'Customer #',
      dataIndex: 'customerNo',
      width: 115,
      blankWidth: 115,
      sorter: (a, b) => sortString(a.customerNo, b.customerNo),
      render: false,
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      width: 220,
      blankWidth: 200,
      sorter: (a, b) => sortString(a.customerName.length, b.customerName.length),
      render: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: null,
      blankWidth: 90,
      sorter: (a, b) => sortString(a.status.length, b.status.length),
      render: false,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: null,
      blankWidth: 90,
      sorter: (a, b) => sortString(a.total, b.total),
      render: (value, record) => value && `$${value.toLocaleString()}`,
    },
    {
      title: 'Delivery Date',
      dataIndex: 'deliveryDate',
      width: 140,
      blankWidth: 140,
      sorter: (a, b) => {
        const prevDate = moment(a.deliveryDate);
        const currDate = moment(b.deliveryDate);
        return prevDate.diff(currDate);
      },
      render: deliveryDate => {
        if (deliveryDate && deliveryDate.trim()) {
          const resultYear = deliveryDate.substr(0, 4);
          const resultMonth = deliveryDate.substr(4, 2);
          const resultDay = deliveryDate.substr(6, 2);
          return resultMonth + '/' + resultDay + '/' + resultYear;
        } else {
          return null;
        }
      },
    },
  ],
};

export const CONST_BUSINESS_EVENT_LOG_COLUMNS = {
  key: 'Business Event Log',
  columns: [
    {
      title: ' ',
      dataIndex: 'index',
      width: null,
      blankWidth: 40,
      render: (value, record, index) => `${index + 1}`,
    },
    {
      title: 'Date',
      dataIndex: 'dateStamp',
      width: null,
      blankWidth: 100,
    },
    {
      title: 'Time',
      dataIndex: 'timeStamp',
      width: null,
      blankWidth: 100,
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      width: 95,
      blankWidth: 100,
    },
    {
      title: 'Event Source',
      dataIndex: 'source',
      width: 110,
      blankWidth: 120,
    },
  ],
};

export const CONST_SNAPSHOT_DETAILS_COLUMNS = {
  lineNo: 'Line Number',
  itemNo: 'Item Number',
  itemDescription: 'Item Description',
  splitCode: 'Split Code',
  quantityOrdered: 'Quantity Ordered',
  quantityAllocated: 'Quantity Allocated',
  orderDetailExtendedPrice: 'Order Detail Extended Price',
  poNumber: 'PO Number',
  poLineNumber: 'PO Line Number',
  substitureReferLNo: 'Substitute Reference Line N',
  orderReasonCode: 'Order Reason Code',
  orderLineErrorIndicator: 'Order Line Error Indicator',
  originalItemUnitPrice: 'Original Item Unit Price',
  opCo: 'OpCo',
  eventId: 'Event Id',
  orderNumber: 'Order Number',
  orderDate: 'Order Date',
  customerShipTo: 'Customer Ship-To',
  orderDetailLineNo: 'Order Detail Line Number',
  dropShipIndicator: 'Drop Ship Indicator',
  quantity: 'Quantity',
  quantityAllocAgainstOnHand: 'Quantity Alloc Against On Hand',
  orderDetailAllocated: 'Order Detail Allocated',
  shippedQuantity: 'Shipped Quantity',
  handPriceFlag: 'Hand Price Flag',
  orderStatusCode: 'Order Status Code',
  purchaseOrder: 'Purchase Order',
  substituteReferenceLineNbr: 'Substitute Reference Line Nbr',
  originallyOrderedItemNumber: 'Originally Ordered Item Number',
  referencedOrderNumber: 'Referenced Order Number',
  conversionFactor: 'Conversion Factor',
  transactionQuantity: 'Transaction Quantity',
  demandStatus: 'Demand Status',
  stockType: 'Stock Type',
  substitutionReasonCode: 'Substitution Reason Code',
  substitutionQuantity: 'Substitution Quantity',
  partialFillRequest: 'Partial Fill Request',
  invoice: 'Invoice',
  shipIfAvailFlag: 'Ship If Avail Flag',
  pricingAgreementsIndicator: 'Pricing Agreements Indicator',
  vendorNumber: 'Vendor Number',
  customerPreferenceVendor: 'Customer Preference Vendor',
  subfileReferenceLineNumber: 'Subfile Reference Line Number',
};

export const CONST_SNAPSHOT_HEADER_COLUMNS = {
  orderType: 'Order Type',
  customerShipTo: 'Customer Ship-To',
  purchaseOrderNumber: 'Purchase Order Number',
  customerCategoryCode: 'Customer Category Code',
  routingGroupCode: 'Routing Group Code',
  routeToNumber: 'Route To Number',
  routeNumber: 'Route Number',
  separateInvoice: 'Separate Invoice',
  orderDate: 'Order Date',
  shipPickupDate: 'Ship/Pickup Date',
  withNextOrderIndicator: 'With Next Order Indicator',
  orderStatusCode: 'Order Status Code',
  totalOrderWeight: 'Total Order Weight',
  totalOrderCube: 'Total Order Cube',
  totalNoLinesInOrder: 'Total No.Lines in Order',
  orderTotal: 'Order Total',
  orderGrandTotal: 'Order Grand Total($)',
  deliveryMethod: 'Delivery Method',
  manifestNumber: 'Manifest Number',
  immediateOrderFlag: 'Immediate Order Flag',
  incompleteOrderFlag: 'Incomplete Order Flag',
  deliveryDate: 'Delivery Date',
  holdOrderFlag: 'Hold Order Flag',
  releaseOrderFlag: 'Release Order Flag',
  holdOrderReasonCode: 'Hold Order Reason Code',
  releaseOrderReasonCode: 'Release Order Reason Code',
  taxAmount: 'Tax Amount($)',
  sendImmediateOrderToRouting: 'Send Immediate Order to Routing',
  remoteOrderEntryMode: 'Remote Order Entry Mode',
  recordLogicallyLockedFlag: 'Record Logically Locked Flag',
  errorStatusFlag: 'Error Status Flag',
  approvalFlag: 'Approval Flag',
  laptopZidNumber: 'Laptop ZID Number',
  stopNumber: 'Stop Number',
  m6ConfirmNumber: 'M6 Confirm Number',
  containerGroupID: 'Container Group ID',
  separateContainerFlag: 'Separate Container Flag',
  orderAttribute: 'Order Attribute',
  opCo: 'OpCo',
  eventId: 'Event Id',
  orderNumber: 'Order Number',
  willCallWindow: 'Will Call Window',
};
