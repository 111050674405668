import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SearchFiltersPanel from './SearchFiltersPanel';
import { CommonTable } from '../common';
import { CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS } from '../../common/manageAccountsAuditTrailConstants';
import { CONST_PAGINATION_TEN } from '../../common/constants';
import { useFetchAccountsAuditTrailData,useResetAccountsAuditTrailData } from './redux/hooks';
import { renderTableColumns } from '../../common/renderColumns';
export default function ManageAccountsAuditTrail() {
  const {
    accountsAuditData,
    fetchAccountsAuditTrailDataPending,
    searchConditionData, 
  } = useSelector(state => ({
    accountsAuditData: state.manageSubs.accountsAuditData,
    fetchAccountsAuditTrailDataPending: state.manageSubs.fetchAccountsAuditTrailDataPending,
    searchConditionData: state.manageSubs.searchConditionData,
  }));
  const { fetchAccountsAuditTrailData }  = useFetchAccountsAuditTrailData();
  const { resetAccountsAuditTrailData } = useResetAccountsAuditTrailData();
  const renderColumnsElement = [
    {},//site placeholder
    { //render siteID
      extendRender: (value, label, record) => {
        if(value && value.length > 0) {
          return (
            <>
              {value}
            </>
          )
        }
        return (
          <>
            {record.customerNumber}
          </>
        )
      }
    },
    {},//log placeholder
    {},//oldValue placeholder
    {},//newValue placeholder
    {},//userId placeholder
    {},//modifiedDate placeholder
    {},//modifiedTimestamp placeholder
  ]
  let columns = renderTableColumns(CONST_ACCOUNTS_AUDIT_TRAIL_TABLE_COLUMNS, null, renderColumnsElement);
  return (
    <div className="manage-subs-manage-accounts-audit-trail">
      <div className="chart-title font-size-16-bold" style={{font: "Myriad Bold"}}>Search Accounts Audit Trail</div>
      <SearchFiltersPanel />
      <div className="flex align-item-center justify-end" style={{marginBottom:"20px"}}></div>
      <CommonTable 
        rowKey={Math.random()}
        columns={columns}
        tableClassName={accountsAuditData ? "outs-report-table" : ""}
        datasource={accountsAuditData}
        loadingData={fetchAccountsAuditTrailDataPending}
        paginationStyle={{textAlign: "right", margin: '16px 0px'}}
        triggerConditions={searchConditionData}
        onFecthData={fetchAccountsAuditTrailData}
        onResetStore={resetAccountsAuditTrailData}
        scrollStyle={{ x: 'max-content', y: 300 }}
        paginationSetting={CONST_PAGINATION_TEN}
      />
    </div>
  );
};

ManageAccountsAuditTrail.propTypes = {};
ManageAccountsAuditTrail.defaultProps = {};
